<div class="navbar-fixed-top">

  <!-- Top Menu -->
  <header class="header">
    <div class="app-container">

      <div class="flex-center">
        <a class="logo bold pull-left" style="margin-right:15px">
          <img src="assets/img/CC_small_colour_1.png" style="width: 35px">
        </a>
        <!-- <div *ngIf="test_mode == '1'" class="ml-4">
          <div (click)="modal = 'training-timer'" class="training-mode">Simulation mode</div>
        </div> -->
      </div>


      <div class="flex-center bottom">
        <div class="nav-items">
          <a [routerLinkActive]="'active'" [routerLink]="['/applications/dashboard']" class="nav-item" type="button">
            <div class="icon"><i class="fa fa-users"></i></div>
            <div class="text d-inline-flex align-items-center">
              Requests
            </div>
          </a>
          <a [routerLinkActive]="'active'" [routerLink]="['/portfolio/dashboard']" class="nav-item" type="button">
            <div class="icon"><i class="fa fa-briefcase"></i></div>
            <div class="text d-inline-flex align-items-center">
              Portfolio
            </div>
          </a>
          <div class="dropdown more-menu">
            <a class="nav-item dropdown-toggle" id="moreMenu2" data-toggle="dropdown" aria-haspopup="true"
               aria-expanded="true">
              <div class="icon"><i class="fa fa-ellipsis-h"></i></div>
              <div class="text">More</div>
            </a>
            <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="moreMenu2">
              <!-- <li>
                <a [ngClass]="{disabled: disableLinkIfNotRole('operations')}" [routerLinkActive]="'active'"
                   [routerLink]="['/ecosystems']">Ecosystems</a>
              </li> -->
              <li>
                <a [ngClass]="{disabled: disableLinkIfNotRole('operations')}" [routerLinkActive]="'active'"
                   [routerLink]="['/operations/queue']">Disbursement Queue</a>
              </li>
              <li>
                <a [ngClass]="{disabled: disableLinkIfNotRole('operations')}" [routerLinkActive]="'active'"
                   [routerLink]="['/operations/bulk_payment']">Approved Disbursements</a>
              </li>
              <li>
                <a  [routerLink]="['/operations/repayment-report']">Repayment Report</a>
              </li>
            </ul>
          </div>
        </div>

        <!-- Right side Icon Links -->
        <div class="nav notify-row pull-right" id="top_menu" style="margin-top:0px !important">
          <ul class="nav top-menu " style=" margin-top: 0;">
            <li class="dropdown user-dropdown mt-1">
              <a data-toggle="dropdown" class="dropdown-toggle " href="#">
                <img alt="" class="tiny" src="assets/img/avatar-mini.png">
                <b class="caret"></b>
              </a>
              <ul class="dropdown-menu dropdown-menu-right">
                <li class="logged-in-user">
                  <div class="image">
                    <img alt="" class="tiny" src="assets/img/avatar-mini.png">
                  </div>
                  <div class="name">
                    {{currentUser?.legal_name}}
                  </div>
                </li>
                <li><a [routerLinkActive]="'active'" [routerLink]="['/system/settings/general']"
                    class="lg flex align-center">
                    <i class="fa fa-cog mr-2"></i> Settings</a></li>
                <li role="separator" class="divider"></li>
                <li><a [routerLinkActive]="'active'" [routerLink]="['/login']" class="lg flex align-center">
                    <i class="fa fa-power-off mr-2"></i> Logout</a></li>
              </ul>
            </li>

          </ul>
        </div>
      </div>
    </div>

  </header>
</div>

<div *ngIf="open_profile_analysis_modal">
  <div class="overlay_ app_overlay animated">
    <div class="col-md-12">
      <a class="dismiss cancel_treat_ close" role="button" (click)="open_profile_analysis_modal=false;">
        <i class="fa fa-times" aria-hidden="true"></i>
      </a>
      <div class="overpanel-content floating-title wide no-padding" style="top: 45px;">
        <app-eligibilityanalysismodal [band_id]="band_id" [ecosystem]="ecosystem"></app-eligibilityanalysismodal>
      </div>
    </div>
  </div>
</div>

<div *ngIf="newLoanOpen">
  <div class="overlay_ app_overlay animated" style="overflow:auto;background:#fff;">

    <div class="col-md-12">
      <a class="dismiss cancel_treat_ close" role="button" (click)="newLoanOpen=false">
        <i class="fa fa-times" aria-hidden="true"></i>
      </a>
    </div>
    <div class=" overpanel-content container" role="document"
      style="text-align:left; padding-top:0px;padding-left:0px;padding-right:0px; overflow:hidden; border-radius: 5px;">
      <app-requestform></app-requestform>
    </div>
  </div>
</div>

<div *ngIf="newCustomerRecord">
  <div class="overlay_ app_overlay animated" style="overflow:auto;background:#fff;">

    <div class="col-md-12">
      <a class="dismiss cancel_treat_ close" role="button" (click)="newCustomerRecord=false">
        <i class="fa fa-times" aria-hidden="true"></i>
      </a>
    </div>
    <div class=" overpanel-content container" role="document"
      style="text-align:left; padding-top:0px;padding-left:0px;padding-right:0px; overflow:hidden; border-radius: 5px;">
      <app-requestform [editing_customer]="true"></app-requestform>
    </div>
  </div>
</div>
<div class="overlay_ app_overlay animated ln_mdl" style="overflow:hidden" *ngIf="openShowInterest">

  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="closeShowInterest()">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>

  <div class=" overpanel-content container  animated bounceIn" role="document"
    style="background:rgba(255, 255, 255, 0.95); height:100%; text-align:left;width:650px; padding-top:0px;padding-left:0px;padding-right:0px; overflow:hidden">

    <div class="modl text-center">

      <div class="modl_title" style="margin-bottom: 50px">
        <h2>
          <span class="text-success">Show Interest</span>

        </h2>
      </div>

      <div style="width: 500px; margin: 0 auto 25px">

      </div>

      <div style="width: 200px; margin: 0 auto">
        <div style="margin-bottom: 30px">
          <button class="text-center txt t_l4 btn-block"
            [ngClass]="{'btn-danger': is_done=='0', 'btn-success': is_done=='1'}"
            style="padding-top: 12px; padding-bottom: 12px" (click)="makeOfferToAdvertisedLoan()"
            [disabled]="loading||is_done=='1'">
            <strong *ngIf="is_done=='0'">Make Offer</strong>
            <i class="fa fa-spinner fa-spin" *ngIf="loading && is_done=='0'"></i>
            <i class="fa fa-check" *ngIf="!loading && is_done=='1'"></i>
          </button>
        </div>

      </div>
    </div>


  </div>
</div>
<!-- <div class="overlay_ app_overlay animated" *ngIf="updatingofferletter">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="updatingofferletter=!updatingofferletter">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title wide small" role="document">
    <app-brodcast [mobile]="offerletterdata"></app-brodcast>
  </div>
</div> -->
<!-- <div class="overlay_ app_overlay animated" *ngIf="emailopen =='header'">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="emailopen=''">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title widest small" role="document">
    <app-email-header-content></app-email-header-content>
  </div>
</div> -->

<!-- <div class="overlay_ app_overlay animated" *ngIf="emailopen =='footer'">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="emailopen=''">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title widest small" role="document">
    <app-email-footer-content></app-email-footer-content>
  </div>
</div> -->
<!-- <div class="overlay_ app_overlay animated" *ngIf="emailopen =='welcome_email'">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="emailopen=''">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title widest small" role="document">
    <app-welcome-email></app-welcome-email>
  </div>
</div> -->
<div class="overlay_ app_overlay animated" *ngIf="emailopen =='offer_letter'">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="emailopen=''">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title widest small" role="document">
    <app-offer-letter></app-offer-letter>
  </div>
</div>
<!-- <div class="overlay_ app_overlay animated" *ngIf="mailadveropen">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="mailadveropen = false">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title widest small" role="document">
    <app-welcome-email [for_advert]=true></app-welcome-email>
  </div>
</div> -->
<!-- <div class="overlay_ app_overlay animated" *ngIf="remindercontenteditor">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="remindercontenteditor = false">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title widest small" role="document">
    <app-welcome-email (onSubmitSMSMessageContentUpdate)="onSubmitSMSMessageContentUpdate($event)"
      (onSubmitMessageContentUpdate)="onSubmitMessageContentUpdate($event)"
      [is_for_email_reminder]="is_for_email_reminder" [for_advert]=true [for_content]="true"
      [message_subject]="message_subject" [message_content]="message_content" [message_type]="message_type">
    </app-welcome-email>
  </div>
</div> -->
<!-- <div class="overlay_ app_overlay animated" *ngIf="emailopen =='phone_otp'">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="emailopen=''">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title widest small" role="document">
    <app-phone-otp></app-phone-otp>
  </div>
</div>
<div class="overlay_ app_overlay animated" *ngIf="emailopen =='reset_pin'">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="emailopen=''">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title widest small" role="document">
    <app-rest-pin></app-rest-pin>
  </div>
</div>
<div class="overlay_ app_overlay animated" *ngIf="emailopen =='direct_debit'">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="emailopen=''">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title widest small" role="document">
    <app-direct-debit-email></app-direct-debit-email>
  </div>
</div>
<div class="overlay_ app_overlay animated" *ngIf="emailopen =='validate_work'">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="emailopen=''">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title widest small" role="document">
    <app-validation-work-email></app-validation-work-email>
  </div>
</div> -->
<!-- <div class="overlay_ app_overlay animated" *ngIf="emailopen =='loan_rejection'">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="emailopen=''">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title widest small" role="document">
    <app-loan-rejection-email></app-loan-rejection-email>
  </div>
</div> -->
<!-- <div class="overlay_ app_overlay animated" *ngIf="emailopen =='payment_received'">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="emailopen=''">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title widest small" role="document">
    <app-payment-received></app-payment-received>
  </div>
</div> -->
<!-- <div class="overlay_ app_overlay animated" *ngIf="emailopen =='borrower_received_email'">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="emailopen=''">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title widest small" role="document">
    <app-borrower-received-offer-email></app-borrower-received-offer-email>
  </div>
</div>
<div class="overlay_ app_overlay animated" *ngIf="emailopen =='lender_make_offer'">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="emailopen=''">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title widest small" role="document">
    <app-lender-make-offer></app-lender-make-offer>
  </div>
</div>
<div class="overlay_ app_overlay animated" *ngIf="emailopen =='borrower_accepted'">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="emailopen=''">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title widest small" role="document">
    <app-borrower-accpected-offer-letter></app-borrower-accpected-offer-letter>
  </div>
</div> -->
<!-- <div class="overlay_ app_overlay animated" *ngIf="emailopen =='withdrawal_email'">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="emailopen=''">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title widest small" role="document">
    <app-welcome-email></app-welcome-email>
  </div>
</div> -->
<!-- <div class="overlay_ app_overlay animated" *ngIf="emailopen =='send_to_market'">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="emailopen=''">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title widest small" role="document">
    <app-sent-to-market></app-sent-to-market>
  </div>
</div>
<div class="overlay_ app_overlay animated" *ngIf="emailopen =='guarantor_email'">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="emailopen=''">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title widest small" role="document">
    <app-guarantor-request-email></app-guarantor-request-email>
  </div>
</div>
<div class="overlay_ app_overlay animated" *ngIf="emailopen =='card_request_email'">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="emailopen=''">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title widest small" role="document">
    <app-card-request-email></app-card-request-email>
  </div>
</div> -->
<!-- <div class="overlay_ app_overlay animated" *ngIf="emailopen =='bank_account_confirmation'">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="emailopen=''">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title widest small" role="document">
    <app-bank-account-confirmation-email></app-bank-account-confirmation-email>
  </div>
</div>
<div class="overlay_ app_overlay animated" *ngIf="emailopen =='request_attachment'">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="emailopen=''">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title widest small" role="document">
    <app-request-for-attachments></app-request-for-attachments>
  </div>
</div>
<div class="overlay_ app_overlay animated" *ngIf="emailopen =='bvn_verification'">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="emailopen=''">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title widest small" role="document">
    <app-bvn-verification-email></app-bvn-verification-email>
  </div>
</div> -->
<!-- <div class="overlay_ app_overlay animated" *ngIf="emailopen =='repayment_email'">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="emailopen=''">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title widest small" role="document">
    <app-repayment-reminder-email></app-repayment-reminder-email>
  </div>
</div>
<div class="overlay_ app_overlay animated" *ngIf="emailopen =='loan_disbursed'">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="emailopen=''">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title widest small" role="document">
    <app-loan-disbursed-email></app-loan-disbursed-email>
  </div>
</div>
<div class="overlay_ app_overlay animated" *ngIf="emailopen =='due-reminder'">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="emailopen=''">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title widest small" role="document">
    <app-due-reminder></app-due-reminder>
  </div>
</div> -->

<!-- <div class="overlay_ app_overlay animated" *ngIf="emailopen =='sms_lateness'">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="emailopen=''">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content overpanel-content-sms widesms" role="document">
    <app-sms-lateness-notification></app-sms-lateness-notification>
  </div>
</div>

<div class="overlay_ app_overlay animated" *ngIf="emailopen =='sms-due-reminder'">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="emailopen=''">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content overpanel-content-sms widesms" role="document">
    <app-sms-due-reminder></app-sms-due-reminder>
  </div>
</div> -->
<div *ngIf="schedule_open">
  <div class="overlay_ app_overlay animated fadeIn">
    <div class="col-md-12">
      <a class="dismiss cancel_treat_ close" role="button" (click)="schedule_open=false">
        <i class="fa fa-times" aria-hidden="true"></i>
      </a>
    </div>
    <div class="overpanel-content floating-title animated zoomIn long wider" role="document">
      <app-loanschedulemodal [record]="record">
      </app-loanschedulemodal>
    </div>
  </div>
</div>
<!-- <div *ngIf="preview_schedule">
  <div class="overlay_ app_overlay animated fadeIn">
    <div class="col-md-12">
      <a class="dismiss cancel_treat_ close" role="button" (click)="preview_schedule=false">
        <i class="fa fa-times" aria-hidden="true"></i>
      </a>
    </div>
    <div class="overpanel-content floating-title animated zoomIn wide" role="document">
      <app-previewrepaymentschedule [schedule]="schedule" [goandgetschedule]='1' [loan_status]='loan_status'
        [parentRouteId]="parentRouteId" [canEditSchedule]="canEditSchedule"></app-previewrepaymentschedule>
    </div>
  </div>
</div> -->
<!-- <div *ngIf="new_collateral_modal">
  <div class="overlay_ app_overlay animated" style="overflow:auto;background:#fff;">

    <div class="col-md-12">
      <a class="dismiss cancel_treat_ close" role="button" (click)="new_collateral_modal=false">
        <i class="fa fa-times" aria-hidden="true"></i>
      </a>
    </div>
    <div class="overpanel-content floating-title tall" role="document">
      <app-newcollateral></app-newcollateral>
    </div>
  </div>
</div>
<div *ngIf="guarantor_contacts_modal">
  <div class="overlay_ app_overlay animated" style="overflow:auto;background:#fff;">

    <div class="col-md-12">
      <a class="dismiss cancel_treat_ close" role="button" (click)="guarantor_contacts_modal=false">
        <i class="fa fa-times" aria-hidden="true"></i>
      </a>
    </div>
    <div class=" overpanel-content container" role="document"
      style="text-align:left; padding-top:0;padding-left:0;padding-right:0; overflow:hidden; border-radius: 5px;">
      <app-guarantorcontacts [request_id]="parentRouteId" [contacts]="contacts"></app-guarantorcontacts>
    </div>
  </div>
</div> -->
<!-- <div *ngIf="add_guarantor_modal">
  <div class="overlay_ app_overlay animated" style="overflow:auto;background:#fff;">

    <div class="col-md-12">
      <a class="dismiss cancel_treat_ close" role="button" (click)="add_guarantor_modal=false">
        <i class="fa fa-times" aria-hidden="true"></i>
      </a>
    </div>
    <div class=" overpanel-content container" role="document"
      style="text-align:left; padding-top:0;padding-left:0;padding-right:0; overflow:hidden; border-radius: 5px;">
      <app-add-guarantor [parentRouteID]="parentRouteId"></app-add-guarantor>
    </div>
  </div>
</div> -->
<div *ngIf="open_portfolio_target_modal">
  <div class="overlay_ app_overlay animated" style="overflow:auto;background:#fff;">

    <div class="col-md-12">
      <a class="dismiss cancel_treat_ close" role="button" (click)="open_portfolio_target_modal=false">
        <i class="fa fa-times" aria-hidden="true"></i>
      </a>
    </div>
    <div class="overpanel-content floating-title tall" role="document">
      <app-portfoliotargetmodal></app-portfoliotargetmodal>
    </div>
  </div>
</div>
<!-- <div class="overlay_ app_overlay animated" *ngIf="overlayModalSection==='specific_analytics'">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="overlayModalSection=''">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title animated zoomIn tall wide" role="document">
    <app-runselectedanalyticsmodal (closeSelectedAnalyticsModal)="overlayModalSection=''" [loan]="loan"
      [parentRouteId]="request_id" [token]="currentUser.token" [analytics_data_status]="analytics_data_status">
    </app-runselectedanalyticsmodal>
  </div>
</div> -->
<div class="overlay_ app_overlay" *ngIf="action == '1'">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="closeChildModal()">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>

  <div class="overpanel-content animated zoomIn floating-title confirmation tall" role="document">
    <h3 class="title flex-column flex-center align-start">Take Ownership<br>
      <small>This implies you are the account officer of the customer.</small>
    </h3>
    <div class="padding-regular text-center">
      <div class="flex flex-column align-center">
        <div class="image-avatar large">
          <img class="img-circle"
            src="https://res.cloudinary.com/africacodes-concepts-limited/image/upload/v1507561173/{{ownershipCustomer?.FILE_NAME | imagenotfound}}">
        </div>
        <p>{{ownershipCustomer?.LEGAL_NAME}}</p>
      </div>
      <label class="form-group">
        <input type="checkbox" name="TRANSFER_ALL_ACCOUNT" [(ngModel)]="TRANSFER_ALL_ACCOUNT">
        <span class="control-label normal">Also take owner of all previous transactions up to date</span>
      </label>

      <div class="mt-4">
        <app-button [ngClass]="{'danger': is_done=='0', 'success': is_done=='1'}"
          (click)="confirmOwner(currentUser,customer)" [disabled]="loading||is_done=='1'"
          [label]="!loading && is_done=='1' ? 'Confirmed' : 'Confirm'" [loading]="loading && is_done=='0'"
          class="small">
        </app-button>
      </div>
    </div>
  </div>
</div>

<div class="overlay_ app_overlay animated fadeIn" *ngIf="overlayModalSection == 'approvingbulkdisburse'">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="overlayModalSection=''">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content animated zoomIn short" role="document">
    <div class="h-group center mb-4 padded">
      <h3 class="title">Bulk Payment Upload</h3>
      <p class="subtitle mb-4">Are you sure you want to approve ?</p>
      <app-button [ngClass]="{'danger': is_done=='0', 'success': is_done=='1'}" (click)="confirmBulkPaymentApproval()"
        [disabled]="loading||is_done=='1'" [label]="!loading && is_done=='1' ? 'Confirmed' : 'Confirm'"
        [loading]="loading && is_done=='0'" buttonclass="mt-3">
      </app-button>
    </div>
  </div>
</div>

<div class="overlay_ app_overlay animated ln_mdl" style="overflow:hidden" *ngIf="action == '2'">

  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="closeChildModal()">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>

  <div class="overpanel-content floating-title tall" role="document">


    <!-- <div class="padding-regular text-center">
        <h3 class="title">Change Ownership<br>
          <small>This implies you are changing the account officer of the customer.</small>
        </h3>

        <div class="flex w-75 mx-auto">
          <div class="flex-1 flex-center">
            <div class="image-avatar medium">
              <img
                src="https://res.cloudinary.com/africacodes-concepts-limited/image/upload/v1507989748/{{ownershipCustomer?.accountofficer?.ACCOUNT_OFF_PHOTO | imagenotfound}}">
            </div>
            <h5>{{ownershipCustomer?.accountofficer?.ACCOUNT_OFF}}</h5>
          </div>
          <div class="flex-1 flex-center flex-column">
            <div class="image-avatar medium">
              <img
                src="https://res.cloudinary.com/africacodes-concepts-limited/image/upload/v1507561173/{{ownershipCustomer?.profile_pic | imagenotfound}}">
            </div>
            <h5>{{ownershipCustomer?.legal_name}}</h5>
          </div>
        </div>
        <div class="flex mt-4 flex-center">
          <label class="form-group">
            <input type="checkbox" name="TRANSFER_ALL_ACCOUNT" [(ngModel)]="TRANSFER_ALL_ACCOUNT">
            <span class="control-label normal">Also take owner of all previous transactions up to date</span>
          </label>
        </div>
        <app-empsearch (lenderChosen)="chooseLender($event)"></app-empsearch>

        <div class="mt-4">
          <app-button [ngClass]="{'danger': is_done=='0', 'success': is_done=='1'}"
            (click)="confirmChangeOwner(currentUser,customer)" [disabled]="loading||is_done=='1'||!chosen_lender"
            [label]="!loading && is_done=='1' ? 'Confirmed' : 'Confirm'" [loading]="loading && is_done=='0'"
            class="small">
          </app-button>
        </div>
      </div> -->
  </div>
</div>

<div class="overlay_ app_overlay animated" *ngIf="posting_bulk_payments">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="posting_bulk_payments=false">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title animated zoomIn wider" role="document">
    <app-postingbulkpayments></app-postingbulkpayments>
  </div>
</div>
<div class="overlay_ app_overlay animated fadeIn" *ngIf="posting_upload_payment">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="posting_upload_payment=false">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title animated wide zoomIn tall" role="document">
    <app-uploadpayments></app-uploadpayments>
  </div>
</div>
<!-- <div class="overlay_ app_overlay animated" *ngIf="posting_break_loans">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="posting_break_loans=false">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title animated zoomIn wider" role="document">
    <app-postingbreakloans></app-postingbreakloans>
  </div>
</div> -->
<!-- <div class="overlay_ app_overlay animated" *ngIf="invitingCustomer">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="invitingCustomer=!invitingCustomer">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content no-padding tall" role="document">
    <app-otherheader [text]="text"></app-otherheader>
    <app-invitingnewcustomer [currentUser]='currentUser'></app-invitingnewcustomer>
  </div>
</div> -->
<!-- <div class="overlay_ app_overlay animated" *ngIf="invitingMultipleCustomer">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="invitingMultipleCustomer=!invitingMultipleCustomer">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content wider" role="document">
    <app-invitemultiplecustomers [currentUser]='currentUser'></app-invitemultiplecustomers>
  </div>
</div> -->
<!-- <div class="overlay_ app_overlay animated" *ngIf="invitingMultipleGuarantors">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button"
      (click)="invitingMultipleGuarantors=!invitingMultipleGuarantors">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content wider" role="document">
    <app-inviteguarantors [currentUser]='currentUser'></app-inviteguarantors>
  </div>
</div> -->
<!-- <div class="overlay_ app_overlay animated" *ngIf="download_disbursement">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="download_disbursement=!download_disbursement">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title tall wide" role="document">
    <app-bulkdisbursementdownload [disbursements]="disbursements" [currentUser]='currentUser'>
    </app-bulkdisbursementdownload>
  </div>
</div>
<div class="overlay_ app_overlay animated" *ngIf="showingUnderConstruction">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="showingUnderConstruction=!showingUnderConstruction">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title tall wide" role="document">
    <app-underconstruction [endDate]='endDate'></app-underconstruction>
  </div>
</div> -->

<!-- <div class="overlay_ app_overlay animated" *ngIf="overLayModalSection == 'cardsopen'">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="overLayModalSection=''">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title" role="document">
    <app-cardmodal [from]="1" [customer_id]="userId" [token]="currentUser.token"></app-cardmodal>
  </div>
</div>

<div class="overlay_ app_overlay animated" *ngIf="overLayModalSection == 'accountsopen'">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="overLayModalSection=''">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title" role="document">
    <app-accountmodal [from]="1" [customer_id]="userId" [token]="currentUser.token"></app-accountmodal>
  </div>
</div> -->
<!-- <div class="overlay_ app_overlay animated fadeIn" *ngIf="updatingApprovalLevels">

  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="updatingApprovalLevels=false">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class=" overpanel-content floating-title animated zoomIn" role="document" style="overflow-y: auto;">
    <app-updatingapprovallevel [APPROVAL_LEVEL_ID_]="APPROVAL_LEVEL_ID_"></app-updatingapprovallevel>
  </div>
</div> -->
<!-- <div class="overlay_ app_overlay animated" *ngIf="sending_email">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="sending_email=false">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title animated zoomIn wider" role="document">
    <app-emailsender [is_multiple]="is_multiple" [userId]="userId" [customer]="customerdetail" [from_where]='1'>
    </app-emailsender>
  </div>
</div> -->
<!-- <div class="overlay_ app_overlay animated" *ngIf="sending_sms">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="sending_sms=false">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title animated zoomIn wider" role="document">
    <app-smssender [is_multiple]="is_multiple" [userId]="userId" [customer]="customerdetail" [from_where]='1'>
    </app-smssender>
  </div>
</div> -->
<div class="overlay_ app_overlay animated fadeIn" *ngIf="overlayEditModalSection == 'requesting_data'">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="overlayEditModalSection=''">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content animated zoomIn short" role="document">
    <!-- <div class="h-group center mb-4 padded">
      <h3 class="title">Requesting {{requestingData?.section}}</h3>
      <p class="subtitle mb-4">An email will be sent to the customer.</p>
      <app-button [ngClass]="{'danger': is_done=='0', 'success': is_done=='1'}" (click)="sendRequestForData()"
        [disabled]="loading||is_done=='1'" [label]="!loading && is_done=='1' ? 'Confirmed' : 'Confirm'"
        [loading]="loading && is_done=='0'" buttonclass="small mt-3 mr-1">
      </app-button>
      <app-linkgenerator [link_type]='requestingData.link_type' [request_id]='loan?.request_id'
        (linkGenerated)="linkGenerated($event)"></app-linkgenerator>
      <div class="form-group mt-2">
        <p>{{link}}</p>
      </div>
    </div> -->
    <!-- <app-requestingdata [customerdetail]="customerdetail" [priority]="priority" [requestingData]="requestingData">
    </app-requestingdata> -->
  </div>
</div>
<app-homecustomersideview *ngIf="drawer" (close)="closeDrawer()" [requestId]="activeRequestId" [loan]="loan"
  (openModal)="openModal($event)">
</app-homecustomersideview>
<!-- <app-createanewcustomer *ngIf="drawer_customer" (close)="closeDrawer()">
</app-createanewcustomer> -->
<!-- <app-fundingbulkrequests (close)="drawer_fund = false" *ngIf="drawer_fund">
</app-fundingbulkrequests> -->
<app-analytics-sideview [band_id]="band_id" [ecosystem]="ecosystem" [type]="type" *ngIf="useDrawer && drawer_analyics"
  (close)="closeDrawer()">
</app-analytics-sideview>

<app-modal *ngIf="modal === 'online-store'" (close)="modal = ''" title="Online store">
  <app-online-store></app-online-store>
</app-modal>

<div class="overlay_ app_overlay animated fadeIn" *ngIf="approving_all_queue">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="approving_all_queue=false">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>

  <div class="overpanel-content animated zoomIn floating-title" role="document">
    <h3 class="title">
      Process Request Queue
    </h3>

    <div class="pd_20">
      <p class="alert alert-danger mb-4 alert-sm">This action quees the current requests. Are you sure ?.</p>
      <p>To generate OTP, whatsapp 'OTP' to 09053535333</p>

      <div class="form-group">
        <label class="control-label normal text-left">Enter OTP</label>
        <input class="form-control" name="DISBURSEMENT_OTP" [(ngModel)]="DISBURSEMENT_OTP">
      </div>
      <div class="mt-2">
        <app-button [ngClass]="{'danger': is_done=='0', 'success': is_done=='1'}" (click)="queue()"
                    [disabled]="loading||is_done=='1'" [label]="!loading && is_done=='1' ? 'Confirmed' : 'Confirm'"
                    [loading]="loading && is_done=='0'" class="small">
        </app-button>
      </div>
      <p style="margin-top:20px" *ngIf="error" class="alert alert-danger mb-4 alert-sm">Invalid OTP Supplied</p>
    </div>
  </div>
</div>
<div class="overlay_ app_overlay animated fadeIn" *ngIf="fundrequest">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="fundrequest = false">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>

  <div class="overpanel-content animated zoomIn flex-center flex-column confirmation" role="document">
    <div class="h-group padded center flex-center flex-column">
      <h3 class="title">Fund Request</h3>
      <p class="subtitle">Are you sure you want to fund this request ?</p>
    </div>
    <div class="text-center">
       
      <app-button [ngClass]="{'red': is_done=='0', 'green': is_done=='1'}" (click)="sendContract()"
        [disabled]="loading||is_done=='1'" [label]="!loading && is_done=='1' ? 'Confirmed' : 'Confirm'"
        [loading]="loading && is_done=='0'">
      </app-button>
    </div>
  </div>
</div>
<app-modal *ngIf="modal === 'training-timer'" (close)="modal = ''">
  <h1 class="title font-weight-bold m-0">Exit Simulation</h1>
  <h1 class="title m-0 mt-2">
    <app-countdown [expiration_date]="expiration_date"></app-countdown>
  </h1>
  <div class="mt-5 fs-base">
    Ensure you go through the request portfolio, dashboard, disbursement channel etc. before going live. Click on the
    button below to exit simulation mode into live
  </div>
  <div class="mt-5">
    <button class="btn btn-primary" disabled>Exit</button>
  </div>

  <img
    src="/assets/images/clock.png"
    alt="" style="position: absolute; bottom: 30px; right: 30px; width: 200px"
  />
</app-modal>
<app-queuemodal *ngIf="launching_p_queue" (close)="launching_p_queue = false">
  <app-lenderpqueue></app-lenderpqueue>
</app-queuemodal>

<div class="overlay_ app_overlay animated fadeIn" *ngIf="pushing_to_kuda">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="pushing_to_kuda=!pushing_to_kuda">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content animated zoomIn short" role="document">
    <div class="h-group center mb-4 padded"> 
      <p class="subtitle mb-4">Are you sure you want to push ?</p>
      <div class="flex mt-4 flex-center">
        <label class="form-group">
          <label class="control-label">Select Partner</label>
          <select class="form-control" name="partner" [(ngModel)]="partner">
              <option value=""></option>
              <option value="40460">Kuda Microfinance Bank</option>
              <option value="40490">Credit Direct Limited</option>
              <option value="40497">Advancly</option>
              <option value="17036">VFD  Microfinance Bank</option>
          </select>
        </label>
      </div>
      <app-button [ngClass]="{'danger': is_done=='0', 'success': is_done=='1'}" (click)="pushnow()"
        [disabled]="loading||is_done=='1'" [label]="!loading && is_done=='1' ? 'Confirmed' : 'Confirm'"
        [loading]="loading && is_done=='0'" buttonclass="mt-3">
      </app-button>
    </div>
  </div>
</div>
